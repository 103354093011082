#current-image {
  width: 30%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#drawn-images {
 width: 10%;
}

h1, h2, h3 {
  text-align: center;
}

#draw-card-button {
  padding: auto auto;
  font-size: 1.5em;
}

#drawn-image-rown {
  margin: auto;

}

#draw-card-button-row {
  display: flex;
  justify-content: center;
  margin: auto;
}

#draw-card-button{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
   width: 15%;
  /* font-size: 2rem;
  background-color: red;
  border-radius: 15px; */
}

#card-images{
  width: 5.5%;
  padding: 0px;
  margin: 0px 2px;
}

#card-rows{
  justify-content: center;
  margin: 0px;
  margin-bottom: 10px;
  padding: 0px;

}

.selected-card {
  border: 5px solid blue;
  border-radius: 3px;
}

.unselected-card {
  border: 2px solid black;
  border-radius: 3px;
}

.row {
  padding: none;
}

#clear-card{
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#card-buttons{
  display: flex;
  justify-content: center;
}

#card-buttons > button{
  margin: 5px;
}

@media only screen and (max-width: 600px) {
  #current-image {
    width: 70%;
  }

  #drawn-images {
    width: 15%;
    margin-bottom: 10px;
   }
   
   #draw-card-button{
    
    width: 35%;
    /* font-size: 2rem;
    background-color: red;
    border-radius: 15px; */
  }


  #drawn-card-container {
    display: flex;
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    justify-content: space-around;
    flex-wrap: wrap;
  }

  #card-images{
    width: 18%;
    /* padding: 0px 2px; */
  }

  #card-rows{
    justify-content: center;  
  }

}